<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <b-row v-else class="justify-content-center">
      <b-col md="9">
        <b-card>
          <b-card-title class="m-0">
            <b-row class="align-items-center text-center">
              <b-col md="4"
                ><h3 class="text-primary">
                  No : {{ requestData.ref_number }}
                </h3></b-col
              >
              <b-col id="centerTitleRequestHeader" md="4"
                ><h3 class="text-primary">
                  {{ $t("g.saudiInspectionAndTesting") }}
                </h3></b-col
              >
              <b-col md="4" class=""
                ><h3 class="text-primary">SAITCO</h3>
                <span>Saudi Inspection & Testing Company</span></b-col
              >
            </b-row>
          </b-card-title>
        </b-card>
        <b-row class="mb-2 justify-content-center">
          <b-col cols="12">
            <b-media class="align-items-center" no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="40">
                  <feather-icon icon="GitCommitIcon" size="25" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h4 class="mb-0">
                  {{ $t("g.basicInformations") }}
                </h4>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
        <b-card>
          <b-row>
            <b-col md="3" class="mb-2">
              <b-media no-body>
                <b-media-body>
                  <h6>
                    {{ $t("g.labName") }}
                  </h6>
                  <h4 class="mb-0">
                    {{ requestData.lab ? requestData.lab.name : null }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col md="3" class="mb-2">
              <b-media no-body>
                <b-media-body>
                  <h6>
                    {{ $t("g.providerName") }}
                  </h6>
                  <h4 class="mb-0">
                    {{ requestData.supplier_name }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-col>
            <!-- <b-col md="3" class="mb-2">
              <b-media no-body>
                <b-media-body>
                  <h6>
                    {{ $t("g.contactPerson") }}
                  </h6>
                  <h4 class="mb-0">
                    {{ requestData.contact_person }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-col> -->
            <b-col md="3" class="mb-2">
              <b-media no-body>
                <b-media-body>
                  <h6>
                    {{ $t("g.phoneNumber") }}
                  </h6>
                  <h4 class="mb-0">
                    {{ requestData.phone_number }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col md="3" class="mb-2">
              <b-media no-body>
                <b-media-body>
                  <h6>
                    {{ $t("g.email") }}
                  </h6>
                  <h4 class="mb-0">
                    {{ requestData.email }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-col>
            <!-- <b-col md="3" class="mb-2">
              <b-media no-body>
                <b-media-body>
                  <h6>
                    {{ $t("g.address") }}
                  </h6>
                  <h4 class="mb-0">
                    {{ requestData.address }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-col> -->
            <b-col md="3" class="mb-2">
              <b-media no-body>
                <b-media-body>
                  <h6>
                    {{ $t("g.last_response_date") }}
                  </h6>
                  <h4 class="mb-0">
                    {{ format(requestData.last_response_date) }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col md="3" class="mb-2">
              <b-media no-body>
                <b-media-body>
                  <h6>
                    {{ $t("g.typeOfRequest") }}
                  </h6>
                  <h4 class="mb-0">
                    {{
                      requestData.buy_request_type
                        ? requestData.buy_request_type.name
                        : null
                    }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col cols="12">
              <b-media no-body>
                <b-media-body>
                  <h6>
                    {{ $t("g.proofOfCompetence") }}
                  </h6>
                  <h4 class="mb-0">
                    {{ requestData.proof }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card>
        <b-row class="mb-2 justify-content-center">
          <b-col cols="12">
            <b-media class="align-items-center" no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="40">
                  <feather-icon icon="GitCommitIcon" size="25" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h4 class="mb-0">
                  {{ $t("g.items") }}
                </h4>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            v-for="(item, index) in requestData.buy_request_lines"
            :key="index"
          >
            <b-card>
              <b-col cols="12">
                <b-media no-body>
                  <b-media-body>
                    <h6>
                      {{ $t(`g.itemDescriptions`) }}
                    </h6>
                    <h4 class="mb-0">
                      {{ item.desc }}
                    </h4>
                  </b-media-body>
                </b-media>
              </b-col>
              <hr />
              <b-col cols="12">
                <b-media-body>
                  <h6>
                    {{ $t(`g.itemSpecifications`) }}
                  </h6>
                  <h4 class="mb-0">
                    {{ item.spec }}
                  </h4>
                </b-media-body>
              </b-col>
              <hr />
              <b-col cols="12">
                <b-media-body>
                  <h6>
                    {{ $t(`g.count`) }}
                  </h6>
                  <h4 class="mb-0">
                    {{ item.quantity }}
                  </h4>
                </b-media-body>
              </b-col>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="3" v-if="is_reviewed">
        <b-card>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="col-12"
          >
            Print
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-success"
            class="col-12 mt-1 mb-1"
            @click="handleAction('acception')"
          >
            {{ $t("g.acception") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning"
            class="col-12"
            @click="handleAction('rejection')"
          >
            {{ $t("g.rejection") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            class="col-12 mt-1"
            @click="deleteAlert"
          >
            {{ $t("g.delete") }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardTitle,
  BButton,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import formatDate from "@/composables/format-date/format-date";
import store from "@/store/index";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    Loading,
    Error,
    BButton,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
  },
  data() {
    return {
      currentId: null,
      isLoading: true,
      isError: null,
      requestData: null,
      form_data: {
        reviewed_user_id: null,
        is_approved: null,
      },
      format: null,
      is_reviewed: null,
    };
  },
  directives: {
    Ripple,
  },
  created() {
    console.log(store.state.authUser.user.id);

    this.format = formatDate;
    this.currentId = this.$route.params.id;
    // Get data from backend by ID
    this.$http
      .get(`admin/buyRequests/${this.currentId}`)
      .then((res) => {
        this.requestData = res.data.data;
        this.isLoading = false;
        this.isError = false;
        if(store.state.authUser.user.id === res.data.data.requester.id){
          this.is_reviewed = false;
        } else {
          this.is_reviewed = true
        }
      })
      .catch((err) => {
        console.log(err);
        this.isLoading = false;
        this.isError = true;
      });
  },
  methods: {
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },

    handleAction(type) {
      this.form_data.reviewed_user_id = store.state.authUser.user.id;
      if (type === "acception") {
        this.form_data.is_approved = 1;
      } else {
        this.form_data.is_approved = 0;
      }
      let formData = new FormData();
      for (const key in this.form_data) {
        formData.append(key, this.form_data[key]);
      }
      this.$http
        .post(`admin/buyRequests/${this.currentId}`, formData, {
          params: { _method: "put" },
        })
        .then((res) => {
          if (res) {
            setTimeout(() => {
              this.$router.push({ name: "ServicesPurchaseRequestList" });
            }, 500);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.errors) {
            for (const key in err.response.data.errors) {
              this.makeToast(
                "danger",
                err.response.data.errors[key][0],
                this.$t("g.send.errorTitle")
              );
            }
          } else {
            this.makeToast(
              "danger",
              err.response.data.message,
              this.$t("g.send.errorTitle")
            );
          }
        });
    },

    deleteBuyRequest() {
      this.$http
        .delete(`admin/buyRequests/${this.currentId}`)
        .then((res) => {
          setTimeout(() => {
            this.$router.push({ name: "ServicesPurchaseRequestList" });
          }, 500);
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.isError = true;
          for (const key in err.response.data.errors) {
            this.makeToast(
              "danger",
              err.response.data.errors[key][0],
              this.$t("g.send.errorTitle")
            );
          }
        });
    },

    deleteAlert() {
      this.$swal({
        title: this.$t("g.areYouSure?"),
        text: this.$t("g.youWontBeAbleToRevertThis"), // You won't be able to revert this!
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("g.yesDeleteIt"), // "Yes, delete it!"
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteBuyRequest();
          this.$swal({
            icon: "success",
            title: this.$t("g.deleted"), //"Deleted!"
            text: this.$t("g.yourFileHasBeenDeleted"), //"Your file has been deleted."
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
  },
};
</script>

<style>
#centerTitleRequestHeader {
  border-right: 2px solid #ddd;
  border-left: 2px solid #ddd;
}
hr {
  margin: 1rem 0;
}
</style>
